import React from 'react';
import './styles/button.css';
import { Loader } from 'react-feather';

export const Button = (props) => {
  const { customClass, type, content, onClick, size, isLoading, disabled } = props;

  const getFontsize = () => {
    switch(size) {
    case 's': {
      return 16;
    }
    case 'm': {
      return 20;
    }
    case 'l': {
      return 24;
    }
    default: {
      return 20;
    }
    }
  };

  const getButtonClass = () => {
    switch(type) {
    case 'primary': {
      return '--primary';
    }
    case 'secondary': {
      return '--secondary';
    }
    case 'tertiary': {
      return '--tertiary';
    }
    case 'error': {
      return '--error';
    }
    default:
      return '--primary';
    }
  };

  return (
    <button
      className={`${customClass} button button${getButtonClass()}`}
      style={{
        fontSize: `${getFontsize()}px`
      }}
      type='button'
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <div className='button-content'>
        {content}
        {
          isLoading && <div className='button-content__loader'><Loader size={getFontsize()}/></div>
        }
      </div>
    </button>
  );
};