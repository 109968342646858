import React, { useEffect, useState } from 'react';
import './styles/userScore.css';
import { getUsernameFromEmail } from '../utils/formatText';
import { RaceService } from '../services/race';

export const UserScore = (props) => {
  const { currentUser } = props;
  const [scores, setScores] = useState({
    avgaccuracy: 0,
    avgspeed: 0,
    latestscore: 0,
    maxspeed: 0,
    recentavgspeed: 0,
    totalraces: 0,
  });

  const { avgaccuracy: avgAccuracy,
    avgspeed: avgSpeed,
    latestscore: latestScore,
    maxspeed: maxSpeed,
    recentavgspeed: recentAvgSpeed,
    totalraces: totalRaces
  } = scores;

  const getScores = async () => {
    if(currentUser?.id) {
      const fetchedScores = await RaceService.getAggregateScores(currentUser?.id);
      setScores(fetchedScores);
    }
  };

  useEffect(() => {
    getScores();
  }, []);

  return (
    <div className='user-score'>
      <div className='user-score__score'>
        <div className='user-score__score__header'>
          {getUsernameFromEmail(currentUser?.email)}
        </div>
        <div className='user-score__score__container'>
          <div>
            <span>Avg. speed(overall) </span>
            <span>{avgSpeed?.toFixed(0) || 0} wpm</span>
          </div>
          <div>
            <span>Avg. speed(last 10 races) </span>
            <span>{recentAvgSpeed?.toFixed(0) || 0} wpm</span>
          </div>
          <div>
            <span>Avg. accuracy </span>
            <span>{avgAccuracy?.toFixed(1) || 0}%</span>
          </div>
          <div>
            <span>Best speed </span>
            <span>{maxSpeed?.toFixed(0) || 0} wpm</span>
          </div>
          <div>
            <span>Latest speed </span>
            <span>{latestScore?.toFixed(0) || 0} wpm</span>
          </div>
          <div>
            <span>Total races </span>
            <span>{totalRaces?.toFixed(0) || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};