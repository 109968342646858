import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { NavigationContainer } from './components/Navigation';
import './App.css';

function App() {
  return (
    <BrowserRouter basename='/'>
      <div className="App">
        <NavigationContainer />
        <Analytics />
      </div>
    </BrowserRouter>
  );
}

export default App;
