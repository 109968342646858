import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../lib/api';
import { AuthService } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import './styles/signin.css';
import { Button } from './design-elements/Button';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activeInput, setActiveInput] = useState();
  const [loading, setLoading] = useState();
  // resetPassword values -> 'email', 'password'
  const [resetPassword, setResetPassword] = useState('');
  const navigation = useNavigate();

  const handleInput = (e) => {
    setActiveInput(e?.target?.name);
  };

  const validateEmail = (emailString) => {
    return String(emailString)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateForm = () => {
    if(!email) {
      toast.error('Email cannot be blank');
      return false;
    }

    if(!validateEmail(email)) {
      toast.error('Please enter a valid email');
      return false;
    }

    if(!password) {
      toast.error('Password cannot be blank');
      return false;
    }

    if(password.length < 6) {
      toast.error('Password should be minimum 6 characters');
      return false;
    }

    return true;
  };

  const signupUser = async () => {
    setLoading('signup');
    if(!validateForm()) {
      setLoading('');
      return;
    }
    try {
      if(email && password) {
        const userExists = await AuthService.signUp(email, password);
        if(userExists) {
          await loginUser();
        }
        setLoading('');
      }
    } catch (error) {
      toast.error(error.message);
      setLoading('');
      return Promise.resolve();
    } finally {
      setLoading('');
    }
  };

  const loginUser = async () => {
    setLoading('login');
    if(!validateForm()) {
      setLoading('');
      return;
    }
    try {
      if(email && password) {
        await AuthService.login(email, password);
        navigation('/');
      }
    } catch (error) {
      if(error.message === 'Email not confirmed') {
        toast('Please confirm your email to sign in.', {
          icon: '📩'
        });
        setLoading('');
        return Promise.resolve();
      }

      toast.error(error.message);
      setLoading('');
      return Promise.resolve();
    } finally {
      setLoading('');
    }
  };

  const sendPasswordResetLink = async () => {
    if(!validateEmail(email)) {
      toast.error('Please enter a valid email');
      return;
    }
    setLoading('reset');
    try {
      await AuthService.sendPasswordResetLink(email);
      toast.success('Password reset link successfully sent to your email');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading();
    }
  };

  const updatePassword = async () => {
    setLoading(true);
    try {
      await AuthService.resetPassword(password);
      toast.success('Password updated successfully, sign in to continue!');
      setResetPassword('');
    } catch(err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event) => {
      console.log('eventt', event);
      if (event == 'PASSWORD_RECOVERY') {
        setResetPassword('password');
      }
    });
  }, []);

  return (
    <div className='signin'>
      <form className='signin__form' onSubmit={(e) => e.preventDefault()}>
        <div className='signin__form__header'>
          <div className='signin__form__header__title'>
              Enter account details
          </div>
          <div>
              Create a <em>KeyBored</em> account to enter online multiplayer races.
          </div>
        </div>
        <div>
          {
            resetPassword !== 'password' &&
            <div className={`signin__form__field ${activeInput === 'email' ? 'signin__form__field--active' : ''}`}
              onFocus={handleInput}
              onBlur={() => handleInput()}
            >
              <input
                className={`signin__form__field__input ${activeInput === 'email' ? 'signin__form__field__input--active' : ''}`}
                type='email'
                placeholder='Email'
                name='email'
                required
                onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
          }

          {
            resetPassword !== 'email' &&
            <div className={`signin__form__field ${activeInput === 'password' ? 'signin__form__field--active' : ''}`}
              onFocus={handleInput}
              onBlur={() => handleInput()}
            >
              <input
                className={`signin__form__field__input ${activeInput === 'password' ? 'signin__form__field__input--active' : ''}`}
                type='password'
                placeholder='Password'
                name='password'
                required
                onChange={(e) => setPassword(e.target.value)} value={password} />
            </div>
          }
          {
            !resetPassword &&
            <div className='signin__form__field__reset-password' onClick={() => setResetPassword('email')}>
              Forgot password
            </div>
          }
        </div>
        <div className='signin__form__actions'>
          {
            resetPassword ?
              <>
                {
                  resetPassword === 'email' &&
                  <Button
                    content='Send reset link'
                    size='m'
                    type='secondary'
                    onClick={sendPasswordResetLink}
                    isLoading={loading === 'reset'}
                    disabled={loading}
                  />
                }
                {
                  resetPassword === 'password' &&
                  <Button
                    content='Update Password'
                    size='m'
                    type='primary'
                    onClick={updatePassword}
                    isLoading={loading === 'reset'}
                    disabled={loading}
                  />
                }
              </>
              :
              <>
                <Button
                  content='Create account'
                  size='m'
                  type='primary'
                  onClick={signupUser}
                  isLoading={loading === 'signup'}
                  disabled={loading}
                />
                <Button
                  content='Login'
                  size='m'
                  type='tertiary'
                  onClick={loginUser}
                  isLoading={loading === 'login'}
                  disabled={loading}
                />
              </>
          }
          <div className='signin__form__footer'>
              You can practice without logging in.
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signin ;