import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import './styles/raceContainer.css';
import Confetti from 'react-confetti';
import { PlayCircle, Repeat, Terminal, Twitter } from 'react-feather';

import { ScoreHistory } from './ScoreHistory';
import { Button } from './design-elements/Button';
import { RaceService } from '../services/race';
import { Progress } from './design-elements/Progress';
import { ChannelService } from '../services/channel';
import { randomText } from '../constants/text';
import { SCORE_TYPE } from '../constants/db';
import { EXTERNAL_LINKS, historyTabs } from '../constants';
import { MAX_PARTICIPANTS } from '../constants/realtime';
import { removeUserScores } from '../utils/localStorage';
import { TWEET_CTA_TEXT_WITH_HIGH_SCORE, TWEET_CTA_TEXT_WITH_SCORE } from '../constants/templates';

const RaceContainer = (props) => {
  // Props
  const { currentUser } = props;

  // States
  const [timer, setTimer] = useState(60),
    [matchedWords, setMatchedWords] = useState(0),
    [currentInput, setCurrentInput] = useState(''),
    [keystrokes, setKeystrokes] = useState({
      correct: 0,
      incorrect: 0
    }),
    [startRace, setStartRace] = useState(false),
    [endRace, setEndRace] = useState(false),
    [disposeTimer, setDisposeTimer] = useState(),
    [excerpt, setExcerpt] = useState({
      id: 0,
      text: 'Start race to test your typing speed...'
    }),
    [scores, setScores] = useState([]),
    [countDown, setCountDown] = useState(0),
    [disposeCountDown, setDisposeCountDown] = useState(),
    [userScores, setUserScores] = useState({}),
    [newMax, setNewMax] = useState(false),
    [isScoreSaved, setIsScoreSaved] = useState(false),
    [realtimeUsers, setRealtimeUsers] = useState([]),
    [channel, setChannel] = useState(),
    [raceId, setRaceId] = useState(),
    [waiting, setWaiting] = useState(false),
    [mode, setMode] = useState(SCORE_TYPE.RACE),
    [removePresence, setRemovePresence] = useState(),
    [historyTab, setHistoryTab] = useState(historyTabs.RACE),
    [isNoOneOnline, setIsNoOneOnline] = useState(false);

  const navigation = useNavigate();

  // Refs
  const inputRef = useRef(null);

  // Derived values and Functions
  const words = excerpt.text.split(' ');

  const getCorrectMatches = () => {
    let correctMatches = 0;
    let incorrectMatches = 0;

    for (let i = 1; i <= currentInput.length; i++) {
      if (!words?.[matchedWords]?.startsWith(currentInput.slice(0, i))) {
        incorrectMatches = i;
        break;
      }
      correctMatches = i;
    }
    return { correctMatches, incorrectMatches };
  };

  const matchedWordsLength = words.slice(0, matchedWords).join(' ').length;
  const spaceLength = matchedWordsLength ? 1 : 0;
  const correctTextLength = matchedWordsLength + spaceLength + getCorrectMatches().correctMatches;
  const correctText = excerpt.text.slice(0, correctTextLength);

  const startIncorrectTextIndex = correctTextLength;
  const endIncorrectTextIndex = matchedWordsLength + spaceLength + currentInput.length;
  const incorrectText = excerpt.text.slice(startIncorrectTextIndex, endIncorrectTextIndex);

  const remainingText = excerpt.text.slice(correctTextLength > endIncorrectTextIndex ?
    correctTextLength : endIncorrectTextIndex, excerpt.text.length
  );

  const typingSpeed = (60 - timer) ? Math.round((correctText.length / 5) / (60 - timer) * 60) : 0;
  const accuracy = keystrokes.correct ? (((keystrokes.correct - keystrokes.incorrect) / keystrokes.correct) * 100).toFixed(1) : 0;
  const completion = (excerpt.text.length - (remainingText.length + incorrectText.length)) / excerpt.text.length;

  const clearStates = () => {
    setEndRace(false);
    setNewMax(false);
    setIsScoreSaved(false);
    setMatchedWords(0);
    setKeystrokes({
      correct: 0,
      incorrect: 0
    });
    setTimer(60);
    setIsNoOneOnline(false);
  };

  // Realtime multiplayer logic
  const initializeRace = async () => {
    if(!currentUser?.id) {
      navigation('/signin');
      return;
    }

    clearStates();
    setRealtimeUsers([]);

    if(currentUser?.id) {
      setWaiting(true);
      const channel = await ChannelService.participate(currentUser?.id);
      const disposeTimeout = setTimeout(() => {
        toast('Looks like you\'re on your own, practice instead?', {
          icon: '⏳'
        });
        setIsNoOneOnline(true);
      }, 10000);

      channel
        .on('presence', { event: 'join' }, ({ newPresences }) => {
          setRealtimeUsers(realtimeUsers => [...realtimeUsers, ...newPresences]);
          if(newPresences?.[0]?.user?.id !==  currentUser?.id) {
            clearTimeout(disposeTimeout);
          }
          if(Object.keys(channel.presence.state).length === MAX_PARTICIPANTS) {
            handleStart(channel?.topic?.split?.(':')?.[1]?.split?.('-')?.[0]);
          }
        })
        .on('presence', { event: 'leave' }, ({ leftPresences }) => {
          setRemovePresence(leftPresences);
        })
        .on('broadcast', { event: 'update-state'}, ({ payload }) => {
          setRealtimeUsers(realtimeUsers => realtimeUsers.map((user)=>{
            if(user.user.id === payload.userId) {
              return {
                ...user,
                typingSpeed: payload.typingSpeed,
                completion: payload.completion
              };
            }
            return user;
          }));
        })
        .on('broadcast', { event: 'race-created'}, ({ payload }) => {
          setRaceId(payload.raceId);
        })
        .subscribe(async (status) => {
          if (status === 'SUBSCRIBED') {
            await channel.track({ online_at: new Date().toISOString(), user: currentUser });
          }
        });

      setChannel(channel);
    }
  };

  // Race handlers
  const handleStart = async (excerptId) => {
    clearStates();
    if(excerptId) {
      setMode(SCORE_TYPE.RACE);
      setHistoryTab(historyTabs.RACE);
      // Race mode
      await fetchRaceExcerpt(excerptId);
    } else {
      setMode(SCORE_TYPE.PRACTICE);
      setHistoryTab(historyTabs.PRACTICE);
      // Practice mode
      await fetchRandomExcerpt();
    }

    setCountDown(3);
    setWaiting(false);

    await new Promise(resolve => setTimeout(resolve, 3000));

    setStartRace(true);

    let disposeTimerValue = setInterval(() => {
      setTimer(timer => timer - 1);
    }, 1000);

    setDisposeTimer(disposeTimerValue);
  };

  const handleEnd = async () => {
    setEndRace(true);
    setStartRace(false);
    setCurrentInput('');
    disposeTimer && clearInterval(disposeTimer);
  };

  const findDifference = (previousInput, updatedInput) => {
    if(previousInput.length > updatedInput.length) {
      return {
        operation: 'deletion',
        difference: previousInput.slice(updatedInput.length)
      };
    } else {
      return {
        operation: 'addition',
        difference: updatedInput.slice(previousInput.length)
      };
    }
  };

  const handleInputChange = (e) => {
    const { operation } = findDifference(currentInput, e.target.value);

    if(operation === 'addition') {
      if((words[matchedWords] + ' ').startsWith(e.target.value) && keystrokes.correct <= correctTextLength) {
        setKeystrokes({
          correct: keystrokes.correct + 1,
          incorrect: keystrokes.incorrect
        });
      }

      if(!(words[matchedWords] + ' ').startsWith(e.target.value)) {
        setKeystrokes({
          correct: keystrokes.correct,
          incorrect: keystrokes.incorrect + 1
        });
      }
    }
    setCurrentInput(e.target.value);

    if (words[matchedWords] + ' ' === e.target.value && e.target.value[e.target.value.length - 1] === ' ') {
      setMatchedWords(matchedWords => matchedWords + 1);
      setCurrentInput('');
    } else if (words.length - 1 === matchedWords && words[matchedWords] === e.target.value) {
      setMatchedWords(matchedWords => matchedWords + 1);
      setCurrentInput('');
      handleEnd();
    }
  };

  // Data fetchers
  const fetchRandomExcerpt = async () => {
    const randomExcerpt = await RaceService.getRandomExcerpt();
    if(randomExcerpt) {
      setExcerpt(randomExcerpt);
    } else {
      setExcerpt({
        id: 0,
        text: randomText[0]
      });
    }
  };

  const fetchRaceExcerpt = async (excerptId) => {
    const fetchedExcerpt = await RaceService.getExcerptById(excerptId);
    if(fetchedExcerpt) {
      setExcerpt(fetchedExcerpt);
    } else {
      setExcerpt({
        id: 0,
        text: randomText[0]
      });
    }
  };

  const fetchScores = async () => {
    if(currentUser?.id) {
      const [scoreHistory, fetchedUserScore] = await Promise.all([
        RaceService.getScores(currentUser?.id, SCORE_TYPE.RACE),
        RaceService.getAggregateScores(currentUser?.id)
      ]);
      setScores(scoreHistory);
      setUserScores(fetchedUserScore);
    }
  };

  const fetchScoreHistory = async () => {
    const scoreHistory = await RaceService.getScores(currentUser?.id, historyTab);
    setScores(scoreHistory);
  };

  const updateScores = async (type) => {
    const raceData = {
      speed: typingSpeed,
      time: 60 - timer,
      accuracy:  accuracy,
      excerptId: excerpt.id,
      userId: currentUser?.id || 0,
      type: type,
      completed: (completion * 100)?.toFixed() === 100
    };

    if(mode === historyTab) {
      setScores([{
        id: Math.floor(Math.random() * 100),
        speed: raceData.speed,
        time_taken: raceData.time,
        accuracy: raceData.accuracy,
        excerpt_id: raceData.excerptId,
        user_id: raceData.userId,
        created_at: Date.now(),
        type: raceData.type,
        completed: raceData.completed
      }, ...scores]);
    }

    currentUser?.id && setNewMax(typingSpeed > userScores?.maxspeed);

    try {
      const scoreId = await RaceService.addScores(raceData);

      if(mode === SCORE_TYPE.RACE) {
        if(!raceId) {
          const id = await RaceService.createRace(channel?.topic?.split?.(':')?.[1]?.split?.('-')?.[1], [scoreId]);
          setRaceId(id);

          channel && channel.send({
            type: 'broadcast',
            event: 'race-created',
            payload: {
              raceId: id
            }
          });
        } else {
          await RaceService.updateRace(raceId, scoreId);
        }
      }
      setIsScoreSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const initialize = async () => {
    await fetchScores();
  };

  // Use Effects
  useEffect(() => {
    if(startRace === true){
      inputRef?.current?.focus();
    }

  }, [startRace]);

  useEffect(() => {
    if (timer === 0 && startRace === true) {
      handleEnd();
    }

    if(timer !== 0 && startRace === true && channel && mode === SCORE_TYPE.RACE) {
      channel.send({
        type: 'broadcast',
        event: 'update-state',
        payload: {
          userId: currentUser?.id,
          typingSpeed: typingSpeed,
          completion: completion
        }
      });
    }
  }, [timer]);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    fetchScores();
  }, [currentUser?.id]);

  useEffect(() => {
    if(endRace === true && !isScoreSaved) {
      updateScores(mode);

      if(mode === SCORE_TYPE.RACE) {
        channel && channel.send({
          type: 'broadcast',
          event: 'update-state',
          payload: {
            userId: currentUser?.id,
            typingSpeed: typingSpeed,
            completion: completion
          }
        });
      }

      // Remove scores from local storage to refetch when user visit navbar
      removeUserScores();
    }
  }, [endRace]);

  useEffect(() => {
    if(countDown === 3) {
      let disposeCountDown = setInterval(()=> {
        setCountDown(countDown => countDown - 1);
      }, 1000);
      setDisposeCountDown(disposeCountDown);
    }
    if(countDown === 0 && disposeCountDown) {
      setCountDown(0);
      clearInterval(disposeCountDown);
    }
  }, [countDown]);

  // Race mode only
  useEffect(() => {
    if(removePresence && !startRace && !countDown && !endRace) {
      setRealtimeUsers(realtimeUsers => realtimeUsers.filter((user) => user.presence_ref !== removePresence[0].presence_ref));
      ChannelService.removeParticipantFromChannel(
        channel?.topic?.split?.(':')?.[1]?.split?.('-')?.[1],
        removePresence[0].user.id
      );
      setRemovePresence();
    }
  }, [removePresence]);

  useEffect(() => {
    if(currentUser?.id) {
      fetchScoreHistory();
    }
  }, [historyTab]);

  const getStartRaceHeader = () => {
    if(waiting) {
      if(isNoOneOnline) {
        return <>
          Please wait for other players to join or
          <Button
            content='Practice'
            size={'l'}
            type='secondary'
            onClick={() => handleStart()}
          />
        </>;
      }
      return 'Waiting for other players to join...';
    } else {
      switch(countDown) {
      case 3:
        return 'On your marks . . .';
      case 2:
        return 'Get set . . .';
      case 1:
        return 'Go!';
      default:
        return (
          <>
            <Button
              content={<><PlayCircle size={20} />Race</>}
              size='l'
              onClick={initializeRace}
            />
            <Button
              content={<><Repeat size={20} />Practice</>}
              type='tertiary'
              size='l'
              onClick={() => handleStart()}
            />
          </>
        );
      }
    }
  };

  // Show user ranks
  // const userRanks = realtimeUsers.sort((a, b) => {
  //   let first = {
  //       userId: a?.presence?.user?.id,
  //       typingSpeed: a?.presence?.typingSpeed
  //     }, second = {
  //       userId: b?.presence?.user?.id,
  //       typingSpeed: b?.presence?.typingSpeed
  //     };
  //   if(a?.presence?.user?.id === currentUser?.id) {
  //     first = {
  //       userId: currentUser?.id,
  //       typingSpeed: typingSpeed
  //     };
  //   }
  //   if(b?.presence?.user?.id === currentUser?.id) {
  //     second = {
  //       userId: currentUser?.id,
  //       typingSpeed: typingSpeed
  //     };
  //   }
  //   return first.typingSpeed > second.typingSpeed;
  // });

  // console.log('userRanks', userRanks);

  const highestWpm = realtimeUsers.reduce((acc, presence) => {
    let maxSpeed = acc;
    if(presence.typingSpeed > maxSpeed.typingSpeed) {
      maxSpeed = {
        userId: presence.user.id,
        typingSpeed: presence.typingSpeed
      };
    }
    if(typingSpeed > maxSpeed.typingSpeed) {
      maxSpeed = {
        userId: currentUser?.id,
        typingSpeed: typingSpeed
      };
    }
    return maxSpeed;
  }, {userId: null, typingSpeed: 0});

  return (
    <div className='race-container'>
      <div className='race-container__header'>
        {
          startRace ?
            <div className='race-container__header__text'>
              Type the following text:
            </div> :
            <div className='race-container__header__button'>
              {getStartRaceHeader()}
            </div>
        }
        <div className="race-container__timer">
          {timer}s
        </div>
      </div>

      {
        // Progress bars
        (startRace || endRace || waiting || !!countDown) &&
        [...realtimeUsers.filter((presence)=>presence.user.id !== currentUser?.id), {completion, typingSpeed, user: currentUser}].map((presence) => (
          <Progress key={presence?.user?.id} presence={presence} highestWpm={highestWpm} endRace={endRace} currentUser={currentUser} />
        ))
      }

      <div className="race-container__textarea">
        <span className="race-container__textarea--correct">
          {correctText}
        </span>
        <span className="race-container__textarea--wrong">
          {incorrectText}
        </span>
        {/* <span className="race-container__textarea--cursor">
          |
        </span> */}
        <span className="race-container__textarea--remaining">
          {remainingText}
        </span>
      </div>
      <div className="race-container__inputarea">
        {
          !startRace &&
          <span className="race-container__inputarea__icon">
            <Terminal size={24}/>
          </span>
        }
        <input
          className={`race-container__inputarea__input${incorrectText ? '--error' : ''}`}
          value={currentInput}
          onChange={handleInputChange}
          disabled={!startRace}
          ref={inputRef}
        />
      </div>
      {
        endRace &&
        <div className='race-container__score'>
          <h3 className='race-container__score__header'>
            Final score
            {
              newMax &&
              <span className='race-container__score__container__tag'>New best</span>
            }
            {
              <span className='race-container__score__container__action'>
                <Link to={EXTERNAL_LINKS.TWITTER + '?text=' + encodeURIComponent(
                  newMax ? TWEET_CTA_TEXT_WITH_HIGH_SCORE(typingSpeed) : TWEET_CTA_TEXT_WITH_SCORE(typingSpeed)
                )} target='_blank'>
                  <Twitter width={16} height={16}/>
                </Link>
              </span>
            }
          </h3>
          <div className='race-container__score__container'>
            <div>
              <span>Your speed: </span>
              <span>{typingSpeed} wpm</span>
            </div>
            <div>
              <span>Accuracy: </span>
              <span>{accuracy}%</span>
            </div>
            <div>
              <span>Time: </span>
              <span>{ 60 - timer } secs</span>
            </div>
          </div>
        </div>
      }
      <ScoreHistory
        scores={scores}
        activeTab={historyTab}
        currentUser={currentUser}
        handleHistoryTabChange={setHistoryTab}
      />
      {
        newMax &&
        <Confetti
          width={window.offsetWidth}
          height={window.offsetWidth}
          recycle={false}
          numberOfPieces={newMax ? 500 : 0}
        />
      }
    </div>
  );
};

export default RaceContainer;