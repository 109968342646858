import React, { useEffect, useState } from 'react';
import './styles/leaderboard.css';
import { RaceService } from '../services/race';
import { getUsernameFromEmail } from '../utils/formatText';
import { Award } from 'react-feather';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);

  const fetchLeaderboard = async () => {
    try {
      const data = await RaceService.getLeaderboard();
      if(data && data.length) {
        setLeaderboard(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    leaderboard && leaderboard.length ? <div className='leaderboard'>
      <div className='leaderboard__container'>
        <div className='leaderboard__header'>
          Global leaderboard
          <span className='leaderboard__subheader'>(1 week)</span>
        </div>
        <div className='leaderboard__content'>
          {
            leaderboard.map((score, index) => {
              return (
                <div key={index} className='leaderboard__content__score'>
                  <div className='leaderboard__content__score__award'>
                    <Award/>
                  </div>
                  <div className='leaderboard__content__score__info'>
                    <div className='leaderboard__content__score__info__user'>
                      {getUsernameFromEmail(score.profiles.email)}
                    </div>
                    <div className='leaderboard__content__score__info__speed'>
                      {score.speed} wpm
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
      :
      <></>
  );
};

export default Leaderboard;