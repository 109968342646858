import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './styles/navbar.css';
import { AuthService } from '../services/auth';
import { ReactComponent as Logo } from '../assets/images/keybored-logo-full.svg';
import { Popover } from './Popover';
import { UserScore } from './UserScore';
import { getUsernameFromEmail } from '../utils/formatText';
import { Button } from './design-elements/Button';
import { toast } from 'react-hot-toast';
import { User, GitHub, Twitter } from 'react-feather';
import { EXTERNAL_LINKS } from '../constants';
import { TWEET_CTA_TEXT } from '../constants/templates';

const Navbar = (props) => {
  const { currentUser } = props;
  const navigation = useNavigate();
  const location = useLocation();

  const signOutUser = async (e) => {
    e.preventDefault();
    await AuthService.signOut();
    navigation('/signin');
  };

  useEffect(() => {
    if(currentUser?.id && location.pathname === '/signin') {
      toast.success('Already signed in');
      navigation('/');
    }
  }, [currentUser?.id]);

  return (
    <div className='navbar'>
      <div className='navbar__left'>
        <Link to='/'>
          <Logo className='navbar__left__logo' width={300} />
        </Link>
      </div>
      <div className='navbar__right'>
        <div className='navbar__right__actions'>
          <div className='navbar__right__actions__twitter'>
            <Link to={EXTERNAL_LINKS.TWITTER + '?text=' + encodeURIComponent(TWEET_CTA_TEXT)} target='_blank'>
              <Twitter />
            </Link>
          </div>
          <div className='navbar__right__actions__github'>
            <Link to={EXTERNAL_LINKS.GITHUB} target='_blank'>
              <GitHub />
            </Link>
          </div>
        </div>
        {
          !currentUser?.id ?
            <div className='navbar__right__logged-out'>
              {
                location.pathname === '/signin' ?
                  <Link to='/'>
                    <Button
                      content='Practice'
                      type='secondary'
                      size='m'
                      action={(e)=>e.preventDefault()}
                    />
                  </Link> :
                  <Link to='/signin'>
                    <Button
                      content='Sign In'
                      type='secondary'
                      size='m'
                      action={(e)=>e.preventDefault()}
                    />
                  </Link>
              }
            </div> :
            <div className='navbar__right__logged-in'>
              <Popover
                popoverComponent={
                  <UserScore
                    currentUser={currentUser}
                  />
                }
              >
                <div className='navbar__right__logged-in__user'>
                  <User />
                  <div>
                    <span>
                      {getUsernameFromEmail(currentUser?.email)}
                    </span>
                    <div className='navbar__right__logged-in__user__score'>
                      View scores
                    </div>
                  </div>
                </div>
              </Popover>
              <Button
                content='Sign out'
                size='s'
                type='error'
                onClick={signOutUser}
              />
            </div>
        }
      </div>
    </div>
  );
};

export default Navbar;