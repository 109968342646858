import { supabase } from '../lib/api';
import { getUserScores, setUserScores } from '../utils/localStorage';

export const RaceService = {
  getExcerptById: async (id) => {
    try {
      const { data, error: queryError } = await supabase
        .from('excerpts')
        .select()
        .eq('id', id)
        .limit(1)
        .single();

      if(queryError) {
        return Promise.reject(queryError);
      }

      console.log('RaceService~getExcerptById', data);

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getRandomExcerpt: async () => {
    try {
      const { count, error } = await supabase
        .from('excerpts')
        .select('*', { count: 'exact', head: true });

      if(error) {
        return Promise.reject(error);
      }

      // Assuming there will be all ids present from 1 - count
      let randomRowId = Math.floor((Math.random() * (count - 1)) + 1);

      // Always return 0 when running locally
      if(process.env.NODE_ENV === 'development') {
        randomRowId = 0;
      }

      const { data, error: queryError } = await supabase
        .from('excerpts')
        .select()
        .eq('id', randomRowId)
        .limit(1)
        .single();

      if(queryError) {
        return Promise.reject(queryError);
      }

      console.log('RaceService~getRandomExcerpt', data);

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getExcerpts: async () => {
    try {
      const { data, error } = await supabase
        .from('excerpts')
        .select();

      if(error) {
        return Promise.reject(error);
      }

      console.log('RaceService~getExcerpts', data);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  addScores: async (raceData) => {
    try {
      const { data, error } = await supabase
        .from('scores')
        .insert({
          speed: raceData.speed,
          accuracy: raceData.accuracy,
          time_taken: raceData.time,
          excerpt_id: raceData.excerptId,
          user_id: raceData.userId,
          type: raceData.type,
          completed: raceData.completed
        })
        .select()
        .single();

      if(error) {
        return Promise.reject(error);
      }

      console.log('RaceService~addScores', data);
      return data?.id;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getScores: async (userId, type) => {
    try {
      const { data, error } = await supabase
        .from('scores')
        .select()
        .eq('user_id', userId)
        .eq('type', type)
        .order('created_at', { ascending: false })
        .limit(10);

      if(error) {
        return Promise.reject(error);
      }

      console.log('RaceService~getScores', data);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getAggregateScores: async(userId) => {
    try {
      const scores = getUserScores();
      if(scores) {
        return scores;
      }

      // get_scores is an rpc function defined in supabase
      const { data, error } = await supabase.rpc('get_scores', {
        userid: userId
      }).single();

      if(error) {
        return Promise.reject(error);
      }

      setUserScores(data);

      console.log('RaceService~getAggregateScores', data);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getLeaderboard: async() => {
    try {
      const { data, error } = await supabase.rpc('get_leaderboard');

      if(error) {
        return Promise.reject(error);
      }
      // Get all unique user ids from returned data
      const userIds = [...new Set(data.map(score => score.user_id))];

      // Fetch profile data for userIds
      const { data: profileData, error: profileFetchError } = await supabase
        .from('profiles')
        .select()
        .in('id', userIds);

      if(profileFetchError) {
        return Promise.reject(profileFetchError);
      }

      // Populate profile data in scores
      data.forEach(score => {
        const profile = profileData.find(profile => profile.id === score.user_id);
        score.profiles = profile;
      });

      console.log('RaceService~getLeaderboard', data);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  createRace: async (channelId, scoreIds) => {
    try {
      const { data, error } = await supabase.from('races')
        .insert({
          channel_id: channelId,
          scores: scoreIds
        })
        .select()
        .single();

      if(error) {
        return Promise.reject(error);
      }

      console.log('RaceService~createRace', data);

      return data?.id;
    } catch(err) {
      return Promise.reject(err);
    }
  },

  updateRace: async (raceId, scoreId) => {
    try {
      const { data, error } = await supabase.rpc('append_score_to_race', {
        race_id: raceId,
        score_id: scoreId
      });

      if(error) {
        return Promise.reject(error);
      }

      console.log('RaceService~updateRace', data);

      return data?.id;
    } catch(err) {
      return Promise.reject(err);
    }
  }
};