export const getItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const getUserScores = () => {
  return getItem('user_scores');
};

export const setUserScores = (value) => {
  setItem('user_scores', value);
};

export const removeUserScores = () => {
  removeItem('user_scores');
};