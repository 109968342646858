import { supabase } from '../lib/api';
import { removeItem } from '../utils/localStorage';

const getCurrentSession = async () => {
  try {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      console.log('AuthService~getCurrentSession', error);
      Promise.resolve(error);
    }

    return data;
  } catch (err) {
    return Promise.resolve(err);
  }
};

export const AuthService = {
  signUp: async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      console.log('AuthService~signUp', data, error);

      if (error) {
        return Promise.reject(error);
      }

      if(data.user.id) {
        return true;
      }

      return false;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  login: async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      console.log('AuthService~login', data, error);

      if (error) {
        return Promise.reject(error);
      }

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  signOut: async () => {
    try {
      const { error } = await supabase.auth.signOut();

      console.log('AuthService~signOut', error);

      if (error) {
        return Promise.reject(error);
      }

      removeItem('user_scores');
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },

  sendPasswordResetLink: async (email) => {
    try {
      const { data, error } = await supabase.auth
        .resetPasswordForEmail(email);

      console.log('AuthService~sendPasswordResetLink', data, error);

      if (error) {
        return Promise.reject(error);
      }

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  resetPassword: async (password) => {
    try {
      const { data, error } = await supabase.auth
        .updateUser({ password });

      console.log('AuthService~resetPassword', data, error);

      if (error) {
        return Promise.reject(error);
      }

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  getCurrentUser: async () => {
    return (await getCurrentSession())?.session?.user;
  },

  isUserLoggedIn: () => {
    return getCurrentSession();
  }
};