import React from 'react';
import './styles/progress.css';
import { getUsernameFromEmail } from '../../utils/formatText';

export const Progress = (props) => {
  const { currentUser, presence, highestWpm, endRace } = props;
  return (
    <>
      <div className='progress'>
        <div className='progress__bar'>
          <div className='progress__bar__indicator' style={{
            width: `${(presence.completion * 100) || 0}%`
          }}>
            {(presence?.completion * 100 || 0)?.toFixed()}%
          </div>
        </div>
        <div className='progress__speed'>
          {presence.typingSpeed || 0} wpm
        </div>
      </div>
      <div className='progress__user'>
        {
          presence?.user?.id === currentUser?.id ?
            'You' :
            getUsernameFromEmail(presence?.user?.email)
        }
        {
          (highestWpm.userId === presence?.user?.id && endRace) &&
            <span>
            🏆
            </span>
        }
      </div>
    </>
  );
};