import React, { useRef, useState } from 'react';
import './styles/popover.css';

const POPOVER_WIDTH = 240;

export const Popover = ({children, popoverComponent, popoverWidth = POPOVER_WIDTH, theme = 'dark'}) => {
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef(null);

  const PopoverComponent = () => {
    const parentElement = parentRef?.current,
      parentOffset = parentElement && parentElement.getBoundingClientRect(),
      popoverLeft = parentOffset?.left - ((popoverWidth - parentOffset?.width) / 2),
      popoverTop = parentOffset?.bottom + 12;

    return (
      <div
        className='popover-component'
        style={{
          width: popoverWidth,
          left: popoverLeft,
          top: popoverTop,
          ...theme === 'dark' && {
            backgroundColor: 'black',
            color: 'white'
          }
        }}
      >
        {popoverComponent}
      </div>
    );
  };
  return (
    <div
      className='popover'
      onMouseEnter={setIsOpen}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className='popover-trigger' ref={parentRef}>
        {children}
      </div>
      {isOpen && <PopoverComponent />}
    </div>
  );
};