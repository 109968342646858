import React from 'react';
import './styles/footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__product-hunt'>
        <a href="https://www.producthunt.com/posts/keybored?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-keybored" target="_blank" rel="noreferrer">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=408380&theme=dark" alt="KeyBored - Test&#0032;your&#0032;typing&#0032;speed&#0032;and&#0032;compete&#0032;in&#0032;typing&#0032;races | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" />
        </a>
      </div>
    </div>
  );
};

export default Footer;