export const TWEET_CTA_TEXT = `🚀 Just came across #KeyBored, a type racing game that lets you test and improve your typing speed.
You can also participate in online type races against others.

Join the typing revolution now: https://keybored.pro

#TypingMaster #HighScore
`;

export const TWEET_CTA_TEXT_WITH_SCORE = (score) => `🚀 Just took a typing test on #KeyBored and found out my typing speed is ${score} WPM! 🎉

Find out how fast you can type: https://keybored.pro

#TypingTest
`;

export const TWEET_CTA_TEXT_WITH_HIGH_SCORE = (highScore) => `🚀 Just set a new personal best on #KeyBored with a high score of ${highScore} WPM! 🏆🎉

Find out how fast you can type: https://keybored.pro

#TypingMaster #HighScore
`;