import React from 'react';
import moment from 'moment';
import './styles/scoreHistory.css';
import { historyTabs } from '../constants';
import { Activity } from 'react-feather';

export const ScoreHistory = (props) => {
  const { scores, activeTab, currentUser, handleHistoryTabChange } = props;
  return (
    <div className='score-history'>
      <div className='score-history__tabs'>
        <Activity className='score-history__tabs__icon' size={18}/>
        <div className={`score-history__header${activeTab === historyTabs.RACE ? '--active' : ''}`}
          onClick={() => handleHistoryTabChange(historyTabs.RACE)}
        >Past races</div>
        <div className={`score-history__header${activeTab === historyTabs.PRACTICE ? '--active' : ''}`}
          onClick={() => handleHistoryTabChange(historyTabs.PRACTICE)}
        >Practice scores</div>
      </div>
      {(scores && scores.length) ?
        <table className='score-history__table'>
          <thead className='score-history__table__head'>
            <tr>
              <th>#</th>
              <th>Speed</th>
              <th>Accuracy</th>
              <th>Time</th>
              <th>Created</th>
            </tr>
          </thead>
          {scores.map((score, key) => (
            <tbody key={score.id} className='score-history__table_body'>
              <tr>
                <td>{key + 1}</td>
                <td>{score.speed} wpm</td>
                <td>{score.accuracy}%</td>
                <td>{score.time_taken}s</td>
                <td>{moment(score.created_at).fromNow()}</td>
              </tr>
            </tbody>
          ))}
        </table> :
        <div className='score-history__empty'>
          {
            !currentUser?.id ?
              'Sign in to record your races' :
              'No past races'
          }
        </div>
      }
    </div>
  );
};