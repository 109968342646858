import { MAX_PARTICIPANTS } from '../constants/realtime';
import { supabase } from '../lib/api';
import { RaceService } from './race';

const joinChannel = async (channelData, userId) => {
  try {
    const participants = channelData.participants;
    if(participants.find((user) => user === userId)) {
      // Already present in channel
      console.log('joinChannel user already in channel', userId);
    } else {
      await supabase
        .from('channels')
        .update({participants: [...participants, userId], is_room_full: participants.length === MAX_PARTICIPANTS - 1 })
        .eq('id', channelData.id)
        .select();
    }

    // TODO: Fix self race bug
    const roomId = `${channelData.excerpt_id}-${channelData.id}`;
    const channel = await supabase.channel(roomId);
    return channel;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ChannelService = {
  removeParticipantFromChannel: async (channelId, userId) => {
    try {
      const { data } = await supabase
        .from('channels')
        .select()
        .eq('id', channelId)
        .single();

      await supabase
        .from('channels')
        .update({participants: data.participants.filter((id) => id !== userId)})
        .eq('id', channelId);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },

  participate: async (userId) => {
    try {
      const { data, error } = await supabase
        .from('channels')
        .select()
        .eq('is_room_full', false)
        .eq('is_deleted',false)
        .limit(1);

      if(error) {
        return Promise.reject(error);
      }

      if(data.length) {
        // Found a channel, join the race
        console.log('ChannelService~participate Channel found', data[0]);
        const channel = await joinChannel(data[0], userId);
        return Promise.resolve(channel);
      } else {
        // Create a new channel
        const excerpt = await RaceService.getRandomExcerpt();
        const { data, status } = await supabase
          .from('channels')
          .insert({
            excerpt_id: excerpt.id,
            is_room_full: false,
            participants: [userId],
            is_deleted: false
          })
          .select();

        if(status === 201) {
          const roomId = `${data[0].excerpt_id}-${data[0].id}`;
          const channel = await supabase.channel(roomId);
          console.log('ChannelService~participate Channel created', channel);
          return Promise.resolve(channel);
        } else {
          return Promise.reject('Could not create channel');
        }
      }

    } catch(err) {
      return Promise.reject(err);
    }
  }
};