import React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { supabase } from '../lib/api';

import Navbar from './Navbar';
import RaceContainer from './RaceContainer';
import Signin from './SignIn';

import { AuthService } from '../services/auth';
import { removeUserScores } from '../utils/localStorage';
import Footer from './Footer';
import Leaderboard from './Leaderboard';

export const NavigationContainer = () => {
  const [currentUser, setCurrentUser] = useState({});
  const location = useLocation();

  const getCurrentUser = async () => {
    const user = await AuthService.getCurrentUser();
    setCurrentUser(user);
  };

  useEffect(() => {
    // Clear local storage on mount
    removeUserScores();

    // Get current user
    getCurrentUser();

    // Listens for auth related events and updates state
    // eslint-disable-next-line no-unused-vars
    supabase.auth.onAuthStateChange((event, session) => {
      getCurrentUser();
    });
  }, []);

  return (
    <>
      <Navbar currentUser={currentUser}/>
      <Routes>
        <Route path='/'
          element={<RaceContainer currentUser={currentUser}/>}
        />
        <Route path='/signin'
          element={<Signin currentUser={currentUser}/>}
        />
      </Routes>
      {
        location.pathname === '/' &&
        <Leaderboard />
      }
      <Footer />
      <Toaster
        position='bottom-right'
        toastOptions={{
          style: {
            background: '#000',
            color: '#fff',
          }
        }}
      />
    </>
  );
};